<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";


export default {
  locales: {
    pt: {
      'Monthly Activation': 'Ativação mensal',
      'Month/Year': 'Mês/Ano',
      'Active': 'Ativo',
      'Inactive': 'Inativo',
      'Status': 'Status',
      'Accumulation of Points': 'Pontos Acumulados',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Graduation': 'Graduação',
      'no': 'Não',
      'yes': 'Sim'
    },
    es: {
      'Monthly Activation': 'Ativação mensal',
      'Month/Year': 'Mês/Ano',
      'Active': 'Ativo',
      'Inactive': 'Inativo',
      'Status': 'Status',
      'Accumulation of Points': 'Pontos Acumulados',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Graduation': 'Graduación',
      'no': 'No',
      'yes': 'Si'
    }
  },
  components: { Layout, VclList },
  data() {
    return {
      page: 1,
      pages: 0,


      table: {
        heade: [
          'Month/Year', 'Total', 'Status', 'Accumulation of Points'
        ],

        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  methods: {
    getMonthlyActivation() {
      this.table.body = null

      this.table.loading = true
      this.table.errored = false
      this.table.empty = false

      api
        .get('report/monthly/activation')
        .then(response => {
          if (response.data.status=='success') {
            this.table.body = response.data.list
          } else {
            this.table.body = null;
          }
        })
        .catch(error => {
          this.table.errored = error
        })
        .finally(() => {
          this.table.loading = false
          if (this.table.body=='') {
            this.empty = true
          }
        })
    }
  },
  mounted() {
    this.getMonthlyActivation()
  },
};
</script>

<template>
  <Layout>

    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Monthly Activation') }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap text-center">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(table, index) in table.body" :key="index">
                    <td>{{ table.date }}</td>
                    <td>{{ table.total | currency }}</td>
                    <td>
                      <span class="badge badge-success p-1 font-size-13" v-if="table.active == '1'">{{ t('Active') }}</span>
                      <span class="badge badge-danger p-1 font-size-13" v-else >{{ t('Inactive') }}</span>
                    </td>
                    <td>
                      <span class="badge badge-success p-1 font-size-13" v-if="table.accumulated == 'yes'">{{ t('yes') }}</span>
                      <span class="badge badge-danger p-1 font-size-13" v-else >{{ t('no') }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <paginate
              v-if="!table.errored && !table.empty"
              v-model="page"
              :page-count="pages"
              :page-range="3"
              :margin-pages="2"
              prev-text="<i class='mdi mdi-chevron-left'></i>"
              next-text="<i class='mdi mdi-chevron-right'></i>"
              :container-class="'pagination pagination-rounded justify-content-center mt-4'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-link-class="'page-link'"
              :next-link-class="'page-link'"
              :break-view-link-class="'page-link'">
            </paginate> -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>